<template>
  <div>
    <vs-popup class="sm:popup-w-60" title="Tambah COA" :active="isActive" v-on:update:active="emitModalIsActive">

      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/2 w-full">
            <label class="ml-1 text-xs">Group *</label>
            <v-select :options="groups" :clearable="false" v-model="data.group"/>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <label class="ml-1 text-xs">Tipe *</label>
            <vs-select v-model="data.tipe" class="w-full">
              <vs-select-item v-for="(item, index) in ['HEADER', 'DETAIL']" :key="index" :value="item" :text="item"/>
            </vs-select>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/2 w-full">
            <label class="ml-1 text-xs">Kode *</label>
            <vs-input class="w-full" v-model="data.kode"/>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <label class="ml-1 text-xs">Nama *</label>
            <vs-input class="w-full" v-model="data.nama"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-12/12 w-full">
            <label class="ml-1 text-xs opacity-75 -mt-1">COA Header</label>
            <div @click="modalCoa.active = true">
              <vx-input-group>
                <vs-input placeholder="Pilih COA" :value="data.nama_coa_header" readonly/>
                <template slot="append">
                  <div class="append-text btn-addon">
                    <vs-button type="filled" icon-pack="feather" icon="icon-search"/>
                  </div>
                </template>
              </vx-input-group>
            </div>
          </div>
        </div>
        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
            <vs-button :disabled="isLoading" @click="save">
              <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
              <span v-if="!isLoading">Simpan</span>
            </vs-button>
          </div>
        </div>
      </div>

      <!--modals-->
      <vs-popup class="sm:popup-w-70 popup-content-no-padding"
                title="Pilih COA Header"
                :active="modalCoa.active"
                v-on:update:active="modalCoa.active = $event">
        <Coa :selectable="true" :externalFilter="filterCoa" @selected="onSelectedModalCoa"/>
      </vs-popup>

    </vs-popup>
  </div>
</template>

<script>
import CoaRepository from '@/repositories/master/coa-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import Coa from '@/views/pages/master/coa/Coa'
import vSelect from 'vue-select'

export default {
  name: 'CoaAdd',
  props: ['isActive'],
  components: {
    ValidationErrors,
    Coa,
    'v-select': vSelect
  },
  data () {
    return {
      isLoading: false,
      errors: null,
      modalCoa: {
        active: false
      },
      data: {},
      groups: [
        'ASSETS',
        'LIABILITY',
        'EQUITY',
        'INCOME',
        'COST OF SALES',
        'EXPENSES',
        'OTHER INCOME',
        'OTHER EXPENSE',
        'STATISTIC'
      ]
    }
  },
  computed: {
    filterCoa () {
      return {
        // tipe: 'DETAIL'
      }
    }
  },
  methods: {
    onSelectedModalCoa (data) {
      this.data.id_coa_header = data.id
      this.data.nama_coa_header = data.nama
      this.modalCoa.active = false
    },

    save () {
      this.errors = null
      this.isLoading = true

      CoaRepository.create(this.data)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    onSuccess () {
      this.notifySuccess('Data berhasil disimpan.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
    },

    resetData () {
      Object.assign(this.$data, this.$options.data.call(this))
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
